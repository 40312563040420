import { Routes } from '@angular/router';
import { authGuard } from '@core/guards';

export const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },
  {
    path: '',
    loadComponent: () => import('./pages/main/main.component').then(m => m.MainComponent),
    canActivate: [authGuard],
    children: [
      {
        path: '',
        redirectTo: 'handles',
        pathMatch: 'full'
      },
      {
        path: 'settings',
        loadComponent: () => import('./pages/settings/settings.component').then(m => m.SettingsComponent)
      },
      {
        path: ':agent/handles',
        loadComponent: () => import('./pages/handles/handles.component').then(m => m.HandlesComponent)
      },
      {
        path: ':agent/handles/:id',
        loadComponent: () => import('./pages/handles/handle-info/handle-info.component').then(m => m.HandleInfoComponent),
        children: [
          {
            path: '',
            redirectTo: 'reports',
            pathMatch: 'full'
          },
          {
            path: 'audits',
            loadComponent: () => import('./pages/handles/handle-info/handle-audit/handle-audit.component').then(m => m.HandleAuditComponent)
          },
          {
            path: 'reports',
            loadComponent: () =>
              import('./pages/handles/handle-info/handle-reports/handle-reports.component').then(m => m.HandleReportsComponent)
          }
        ]
      },
      {
        path: ':agent/handles/:id/reports/:reportId',
        loadComponent: () => import('./pages/handles/handle-info/handle-reports/reports/reports.component').then(m => m.ReportsComponent)
      },
      {
        path: ':agent/latest-reports',
        loadComponent: () => import('./pages/latest-reports/latest-reports.component').then(m => m.LatestReportsComponent)
      }
    ]
  },
  {
    path: 'auth-callback',
    loadComponent: () => import('./pages/auth-callback/auth-callback.component').then(m => m.AuthCallbackComponent)
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: 'access-denied',
    loadComponent: () => import('./pages/login/login.component').then(m => m.LoginComponent),
    data: {
      accessDenied: true
    }
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];
