import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

export function ErrorHttpInterceptorFn(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const isUnauthorizedStatus = 401;
  const authService = inject(AuthService);
  return next(request).pipe(
    catchError(err => {
      if (err.status === isUnauthorizedStatus) {
        authService.logout({ logoutParams: { returnTo: window.location.origin } });
      }
      return throwError(() => err);
    })
  );
}
