import { inject } from '@angular/core';
import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { mergeMap, Observable, take } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';

export function IdTokenHttpInterceptorFn(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const authService = inject(AuthService);
  return authService.idTokenClaims$.pipe(
    take(1),
    mergeMap(idToken => {
      if (idToken?.__raw && !request.url.includes('geolocation-db.com')) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${idToken.__raw}`
          }
        });
      }
      return next(request);
    })
  );
}
